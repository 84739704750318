import React, { useEffect } from "react";
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Avatar, Typography, Box } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from 'react-helmet';

import AuthButton from "./components/AuthButton";
import { actions } from '../services/user';
import { RootState } from "../store";
import Logo from "../components/Logo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      signIn: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
      },
      loginCard: {
        width: `calc(100% - ${theme.spacing(4)}px)`,
        maxWidth: 400,
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
      },
      spacer: {
        height: theme.spacing(2),
      }
    }
  ),
);

const getNextUrl = (query?: string) => {
  if (!query) {
    return '/';
  }
  const args = query.split('?')[ 1 ];
  const pairs = args.split('&');
  for (const item of pairs) {
    const [ key, value ] = item.split('=');
    if (key === 'next') {
      return value;
    }
  }

  return '/';
}

const LoginPage: React.FC<RouteComponentProps> = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (state.user) {
      const nextUrl = getNextUrl(props.location?.search);
      navigate(nextUrl);
    }
  }, [ state.user?.id ]);

  const onGoogle = () => {
    dispatch(actions.signIn('google.com'));
  }

  const onGithub = () => {
    dispatch(actions.signIn('github.com'));
  }

  const onFacebook = () => {
    dispatch(actions.signIn('facebook.com'));
  }

  const onTwitter = () => {
    dispatch(actions.signIn('twitter.com'));
  }

  return (
    <div className={classes.signIn}>
      <Helmet>
        <title>Sign In</title>
      </Helmet>

      <Paper className={classes.loginCard}>
        <Box textAlign="center" mt={3} mb={1}>
          <Logo size={120} variant="short" />
        </Box>

        <Typography variant="h6">
          Sign In
        </Typography>
        <div className={classes.spacer} />
        {
          state.error ? <Typography color="error" align="center">{state.error}</Typography> : null
        }
        <div className={classes.spacer} />
        <div className={classes.spacer} />

        <AuthButton type="google.com" onClick={onGoogle} loading={state.signInLoading === 'google.com'} />

        <div className={classes.spacer} />

        <AuthButton type="github.com" onClick={onGithub} loading={state.signInLoading === 'github.com'} />

        <div className={classes.spacer} />

        <AuthButton type="facebook.com" onClick={onFacebook} loading={state.signInLoading === 'facebook.com'} />

      </Paper>
    </div>
  );
};

export default LoginPage;