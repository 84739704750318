import React from "react"
import { Router } from "@reach/router"

import SignIn from '../user/SignIn';
import MyProfile from '../user/MyAccount';


const App = () => (

  <Router>
    <SignIn path="/user/sign-in" />
    <MyProfile path="/user/account" />
  </Router>

)

export default App
